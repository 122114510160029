@font-face {
    font-family: 'Sofia Pro';
    src: url('SofiaPro-Regular.woff2') format('woff2'),
    url('SofiaPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('SofiaPro-Medium.woff2') format('woff2'),
    url('SofiaPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('SofiaPro-Bold.woff2') format('woff2'),
    url('SofiaPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('SofiaPro-Light.woff2') format('woff2'),
    url('SofiaPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

