.progressbar {
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    margin-top: 42%;
}

.scroller-progressbar {
    height: 2px;
}

.event-container {
    position: relative;
}

.scheduler .scheduler-bg .scheduler-bg-table tr {
    border-bottom: none !important;
    background-color: rgba(198, 180, 205, 0.1);
}

.scheduler .scheduler-bg .scheduler-bg-table tr td {
    border-bottom: none !important;
}

.scheduler .scheduler-content .scheduler-content-table tr .event-container {
    box-shadow: inset 0 -10px 0 rgb(255, 255, 255);
}

.resource-table td {
    background-color: rgba(198, 180, 205, 0.1);
    box-shadow: inset 0 -10px 0 rgb(255, 255, 255);
    display: block;
}

.scheduler .resource-table tr, .scheduler .resource-table tr td {
    border-bottom: none !important;
}

.scheduler .ant-row-flex .ant-col .header2-text{
    display: none;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input{
    font-size: 14px;
}

.customDrawer .MuiDialogTitle-root .customDrawerClose{
    color: rgba(0, 0, 0, 0.87) !important;
}

.customDrawer .MuiDialogContent-root .weekendIcon svg {
    height: 20px;
    color: #A0A0A0;
    margin-right: 8px;
    margin-left: 8px;
}

.MuiBox-root-43 {
    padding: 12;
}

.customDrawer .MuiDialogContent-root .weekendTitle {
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 300;
    font-size: 0.9375rem;
}

.scheduler .ant-row-flex{
    margin-bottom: 0px !important;
    background: rgba(214,214,214,0.84);
    height: 30px;
}

.scheduler .ant-row-flex-middle{
    float: right;
    height: 30px;
}

.tt__timelineWrapper{
    margin-top: -0.1% !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    font-size: 14px;
    font-weight: 300;
    font-family: 'Sofia Pro', sans-serif;
}

#RBS-Scheduler-root input, #RBS-Scheduler-root button, #RBS-Scheduler-root select, #RBS-Scheduler-root optgroup, #RBS-Scheduler-root textarea {
    margin: -7px !important;
}

.ant-radio-group {
    font-size: 12px !important;
}

.ant-radio-button-wrapper {
    position: relative !important;
    display: inline-block !important;
    height: 25px !important;
    margin: 0 0px !important;
    padding: 0px 0px !important;
    line-height: 24px !important;
    background: rgba(215, 215, 215, 0) !important;
    border: none !important;
    border-top-width: 0px !important;
    border-left: 0 !important;
    cursor: pointer !important;
    font-family: "Sofia Pro", sans-serif;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    -webkit-box-shadow: -1px 0 0 0 rgba(24, 144, 255, 0) !important;
    box-shadow: -1px 0 0 0 rgba(24, 144, 255, 0) !important;
}

.MuiSvgIcon-root {
    font-size: 1.2rem !important;
    margin-right: 2px;
}

.MuiToolbar-regular {
    min-height: 48px !important;
}

.resource-view {
    /*border-color: #0000ff00 !important;*/
    /*border-right-width: 10px !important;*/
    /*border-right-color: rgba(220, 220, 220, 0.25) !important;*/
}
.resource-view{
    position: relative;
}
.resource-view:after{
    position: absolute;
    content: '';
    width: 10px;
    right: 0;
    top: 0;
    height: 100%;
}

.text_button{
    border: 1px solid rgba(24, 33, 255, 0.92);
    padding: 1px 16px 1px 16px;
    margin: 8px;
    width: 96px;
    text-align: center;
    cursor: pointer;
    color: rgba(24, 33, 255, 0.92);
    font-size: 14px;
    font-weight: 300;
    font-family: "Sofia Pro";
}

.MuiTypography-body1 {
    font-size: 14px !important;
    font-family: "Sofia Pro", sans-serif !important;
}

.persons-sort {
    border-radius: 50%;
    font-size: 10px;
    font-weight: 300;
    height: 22px;
    width: 22px;
    font-family: "Sofia Pro", sans-serif;
    text-align: center;
    padding-top: 1.5%;
    padding-left: 1%;
    padding-right: 1%;
}

.persons-body {
    font-size: 14px;
    font-weight: 300;
    font-family: "Sofia Pro", sans-serif;
}

.error_message {
    color: red;
    font-size: 12px;
    font-weight: 300;
    font-family: "Sofia Pro";
}

.MuiAutocomplete-endAdornment {
    /*display: none;*/
}

.customDrawer .MuiPaper-root {
    background-color: #ebebeb;
}

.tooltipDot{
    position: absolute;
    right: 1px;
    height: 10px;
    width: 10px;
    margin-top: 8px;
    font-weight: 500;
    border-radius: 50%;
}

.tooltip-overflow-text{
    overflow: hidden;
    white-space: nowrap;
    padding-right: 0px !important;
    text-align: left !important;
    font-family: "Sofia Pro", sans-serif;
    font-weight: 300;
}
