@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url(/static/media/SofiaPro-Regular.697c1685.woff2) format('woff2'),
    url(/static/media/SofiaPro-Regular.5aa59c97.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url(/static/media/SofiaPro-Medium.dcb8222f.woff2) format('woff2'),
    url(/static/media/SofiaPro-Medium.b2b22963.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url(/static/media/SofiaPro-Bold.fc9ad71e.woff2) format('woff2'),
    url(/static/media/SofiaPro-Bold.22ed2394.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url(/static/media/SofiaPro-Light.34d871db.woff2) format('woff2'),
    url(/static/media/SofiaPro-Light.ec043083.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}


.MuiToolbar-root .MuiIconButton-label {
  color: #A0A0A0; }
.MuiToolbar-root a.active .MuiIconButton-label {
  color: #000000; }

.MuiTypography-h6 {
  text-align: center; }

@media (min-width: 600px) {
  .makeStyles-drawerHeader-8 {
    min-height: 48px !important; }

  .makeStyles-drawerHeader-504 {
    min-height: 48px !important; }

  .makeStyles-drawerHeader-406 {
    min-height: 48px !important; } }
@media (min-width: 0px) and (orientation: landscape) {
  .makeStyles-drawerHeader-8 {
    min-height: 48px !important; }

  .makeStyles-drawerHeader-504 {
    min-height: 48px !important; } }
.makeStyles-drawerHeader-8 {
  height: 48px !important;
  min-height: 48px !important; }

.button-div {
  font-size: 12px;
  font-weight: 300;
  font-family: "Sofia Pro";
  text-align: center;
  width: 100%; }

.fab-icon {
  position: absolute !important;
  bottom: 16px;
  left: 38px; }

.nav-list {
  background-color: #5765b3;
  color: #fff; }
  .nav-list .MuiSvgIcon-root {
    color: white; }
  .nav-list .MuiListItem-root.Mui-selected, .nav-list .MuiListItem-root.Mui-selected:hover {
    background-color: rgba(30, 144, 255, 0.7) !important; }

/*# sourceMappingURL=MaterialAppbar.css.map */
.progressbar {
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    margin-top: 42%;
}

.scroller-progressbar {
    height: 2px;
}

.event-container {
    position: relative;
}

.scheduler .scheduler-bg .scheduler-bg-table tr {
    border-bottom: none !important;
    background-color: rgba(198, 180, 205, 0.1);
}

.scheduler .scheduler-bg .scheduler-bg-table tr td {
    border-bottom: none !important;
}

.scheduler .scheduler-content .scheduler-content-table tr .event-container {
    box-shadow: inset 0 -10px 0 rgb(255, 255, 255);
}

.resource-table td {
    background-color: rgba(198, 180, 205, 0.1);
    box-shadow: inset 0 -10px 0 rgb(255, 255, 255);
    display: block;
}

.scheduler .resource-table tr, .scheduler .resource-table tr td {
    border-bottom: none !important;
}

.scheduler .ant-row-flex .ant-col .header2-text{
    display: none;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input{
    font-size: 14px;
}

.customDrawer .MuiDialogTitle-root .customDrawerClose{
    color: rgba(0, 0, 0, 0.87) !important;
}

.customDrawer .MuiDialogContent-root .weekendIcon svg {
    height: 20px;
    color: #A0A0A0;
    margin-right: 8px;
    margin-left: 8px;
}

.MuiBox-root-43 {
    padding: 12;
}

.customDrawer .MuiDialogContent-root .weekendTitle {
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 300;
    font-size: 0.9375rem;
}

.scheduler .ant-row-flex{
    margin-bottom: 0px !important;
    background: rgba(214,214,214,0.84);
    height: 30px;
}

.scheduler .ant-row-flex-middle{
    float: right;
    height: 30px;
}

.tt__timelineWrapper{
    margin-top: -0.1% !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    font-size: 14px;
    font-weight: 300;
    font-family: 'Sofia Pro', sans-serif;
}

#RBS-Scheduler-root input, #RBS-Scheduler-root button, #RBS-Scheduler-root select, #RBS-Scheduler-root optgroup, #RBS-Scheduler-root textarea {
    margin: -7px !important;
}

.ant-radio-group {
    font-size: 12px !important;
}

.ant-radio-button-wrapper {
    position: relative !important;
    display: inline-block !important;
    height: 25px !important;
    margin: 0 0px !important;
    padding: 0px 0px !important;
    line-height: 24px !important;
    background: rgba(215, 215, 215, 0) !important;
    border: none !important;
    border-top-width: 0px !important;
    border-left: 0 !important;
    cursor: pointer !important;
    font-family: "Sofia Pro", sans-serif;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: -1px 0 0 0 rgba(24, 144, 255, 0) !important;
}

.MuiSvgIcon-root {
    font-size: 1.2rem !important;
    margin-right: 2px;
}

.MuiToolbar-regular {
    min-height: 48px !important;
}

.resource-view {
    /*border-color: #0000ff00 !important;*/
    /*border-right-width: 10px !important;*/
    /*border-right-color: rgba(220, 220, 220, 0.25) !important;*/
}
.resource-view{
    position: relative;
}
.resource-view:after{
    position: absolute;
    content: '';
    width: 10px;
    right: 0;
    top: 0;
    height: 100%;
}

.text_button{
    border: 1px solid rgba(24, 33, 255, 0.92);
    padding: 1px 16px 1px 16px;
    margin: 8px;
    width: 96px;
    text-align: center;
    cursor: pointer;
    color: rgba(24, 33, 255, 0.92);
    font-size: 14px;
    font-weight: 300;
    font-family: "Sofia Pro";
}

.MuiTypography-body1 {
    font-size: 14px !important;
    font-family: "Sofia Pro", sans-serif !important;
}

.persons-sort {
    border-radius: 50%;
    font-size: 10px;
    font-weight: 300;
    height: 22px;
    width: 22px;
    font-family: "Sofia Pro", sans-serif;
    text-align: center;
    padding-top: 1.5%;
    padding-left: 1%;
    padding-right: 1%;
}

.persons-body {
    font-size: 14px;
    font-weight: 300;
    font-family: "Sofia Pro", sans-serif;
}

.error_message {
    color: red;
    font-size: 12px;
    font-weight: 300;
    font-family: "Sofia Pro";
}

.MuiAutocomplete-endAdornment {
    /*display: none;*/
}

.customDrawer .MuiPaper-root {
    background-color: #ebebeb;
}

.tooltipDot{
    position: absolute;
    right: 1px;
    height: 10px;
    width: 10px;
    margin-top: 8px;
    font-weight: 500;
    border-radius: 50%;
}

.tooltip-overflow-text{
    overflow: hidden;
    white-space: nowrap;
    padding-right: 0px !important;
    text-align: left !important;
    font-family: "Sofia Pro", sans-serif;
    font-weight: 300;
}


.customDialog .MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.71);
}
.customDialog .MuiPaper-root {
  margin: 0;
  max-height: 100%;
  height: 100%;
  position: fixed;
  border-radius: 0;
  width: 60%;
  background: #f7f7f7;
}
.customDialog .MuiDialogTitle-root {
  font-family: "Sofia Pro", sans-serif;
  font-size: 1.5rem;
}
.customDialog .MuiDialogTitle-root textarea {
  width: 100%;
  border: 0;
  color: white;
  background-color: transparent;
  min-height: 35px;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
}
.customDialog .MuiDialogTitle-root textarea:focus, .customDialog .MuiDialogTitle-root textarea:active {
  outline: none;
  border-bottom: 0px solid #ccc677;
}
.customDialog .MuiDialogTitle-root textarea:hover {
  border-bottom: 0px solid #e6e3b6;
}
.customDialog .MuiDialogTitle-root textarea::-webkit-input-placeholder {
  color: #ffffff;
}
.customDialog .MuiDialogTitle-root textarea:-ms-input-placeholder {
  color: #ffffff;
}
.customDialog .MuiDialogTitle-root textarea::-ms-input-placeholder {
  color: #ffffff;
}
.customDialog .MuiDialogTitle-root textarea::placeholder {
  color: #ffffff;
}
.customDialog .MuiDialogTitle-root .dialogDrawerClose {
  color: #ffffff;
  cursor: pointer;
  padding: 2px;
  height: 24px;
  width: 24px;
  margin-top: 5px;
  margin-right: -10px;
}
.customDialog .MuiDialogTitle-root .dialogDrawerClose:hover {
  transition: all 0.2s;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
}
.customDialog .MuiDialogContent-root {
  flex: 1 1 auto;
  padding: 8px 0px;
  overflow: hidden;
  background-color: white;
}
.customDialog .MuiDialogContent-root span {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 0.9375rem;
}
.customDialog .MuiDialogContent-root textarea {
  width: 100%;
  border: 0;
  background-color: rgba(0, 0, 0, 0.08);
  font-family: "Sofia Pro", "inherit";
  padding-top: 5px;
  padding-left: 10px;
  font-size: 15px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
  min-height: 35px;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
}
.customDialog .MuiDialogContent-root textarea:hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.11);
}
.customDialog .MuiDialogContent-root textarea::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.customDialog .MuiDialogContent-root textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.customDialog .MuiDialogContent-root textarea::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.customDialog .MuiDialogContent-root textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.customDialog .MuiDialogContent-root .divHeaders {
  color: #000000;
  font-family: "Sofia Pro", sans-serif;
  font-weight: 400;
  padding-top: 16px;
  padding-left: 32px;
  font-size: 16px;
}
.customDialog .MuiDialogContent-root .divContent {
  overflow: hidden;
  height: 630px;
}
.customDialog .MuiDialogContent-root .divContent:hover {
  overflow-y: auto;
}
.customDialog .MuiDialogContent-root .divLists {
  color: rgba(0, 0, 0, 0.51);
  font-family: "Sofia Pro", sans-serif;
  width: 55%;
  font-weight: normal;
  padding-top: 3px;
  padding-left: 18px;
  font-size: 14px;
}
.customDialog .MuiDialogContent-root .divListStatus {
  color: rgba(0, 0, 0, 0.51);
  font-family: "Sofia Pro", sans-serif;
  font-weight: normal;
  padding-top: 3px;
  padding-left: 10px;
  width: 86px;
  font-size: 14px;
}
.customDialog .MuiDialogContent-root .divColor {
  height: 16px;
  width: 16px;
  margin-top: 4px;
  margin-left: 24px;
  border-radius: 50%;
}
.customDialog .MuiDialogContent-root .editIcon {
  margin-top: 2px;
  margin-left: 55px;
  padding: 6px;
  height: 32px;
  width: 32px;
  color: rgba(0, 0, 0, 0.51);
}
.customDialog .MuiDialogContent-root .editIcon:hover {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
}
.customDialog .MuiDialogContent-root .deleteIcon {
  margin-top: 2px;
  margin-left: 10px;
  padding: 6px;
  height: 32px;
  width: 32px;
  color: rgba(0, 0, 0, 0.51);
}
.customDialog .MuiDialogContent-root .deleteIcon:hover {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
}
.customDialog .MuiDialogContent-root .sidebarIcon {
  display: inline-flex;
  vertical-align: top;
  margin-right: 20px;
}
.customDialog .MuiDialogContent-root .sidebarIcon svg {
  width: 20px;
  height: 20px;
  color: #A0A0A0;
}
.customDialog .MuiDialogContent-root .sidebarTitle {
  color: #7B7B7B;
}
.customDialog .MuiDialogContent-root .sidebarControllerRow {
  margin: 5px 0;
  padding-left: 20px;
  padding-right: 20px;
}
.customDialog .MuiDialogContent-root .sidebarControllerRow .sidebarProjectColor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  vertical-align: bottom;
  margin-right: 20px;
  font-size: 8px;
  justify-content: center;
  align-items: center;
}
.customDialog .MuiDialogContent-root .sidebarControllerRow .sidebarProjectName {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 0.9375rem;
}
.customDialog .MuiDialogContent-root .sidebarControllerRow .sidebarProjectName input {
  padding-left: 10px;
}
.customDialog .MuiDialogContent-root .sidebarControllerRow .timePerDay {
  width: 100%;
  padding: 2px 4px;
  margin-bottom: 6px;
  background-color: rgba(0, 0, 0, 0.08);
}
.customDialog .MuiDialogContent-root .sidebarControllerRow .timePerDay .mui-focused {
  border-bottom: none !important;
}
.customDialog .MuiDialogContent-root .sidebarControllerRow .timePerDay .MuiInput-underline:before {
  border-bottom: 0;
}
.customDialog .MuiDialogContent-root .sidebarControllerRow .timePerDay .MuiInput-underline:after {
  border-bottom: 0;
}
.customDialog .MuiDialogContent-root .sidebarControllerRow .timePerDay input {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 0.9375rem;
  padding-left: 3px;
  padding-top: 6px;
}
.customDialog .createProject {
  display: flex;
  padding: 6px 8px 8px 4px;
  margin-top: 12px !important;
  margin-left: 14px;
  margin-bottom: 60px;
  height: 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  color: rgba(73, 121, 255, 0.85);
}
.customDialog .createProject .create {
  margin-top: -2px;
  margin-left: 10px;
  font-size: 16px;
  font-family: "Sofia Pro", sans-serif;
}
.customDialog .createProject:hover {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.05);
}
.customDialog .sidebarDescriptionWrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}
.customDialog .sidebarDescriptionWrapper textarea {
  border: none;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  resize: none;
  font-family: "Sofia Pro", "inherit";
  font-size: 15px;
  font-weight: 300;
  color: #707070;
}
.customDialog .sidebarDescriptionWrapper textarea:focus {
  outline: none;
}
.customDialog .sidebarMilestoneWrapper {
  margin-top: 30px;
  margin-bottom: 0px;
  height: 148px;
  background: rgba(0, 0, 0, 0.02);
}
.customDialog .sidebarMilestoneWrapper .milestoneTop {
  margin-top: 16px;
}
.customDialog .sidebarMilestoneWrapper .column-div {
  display: inline-block;
}
.customDialog .sidebarMilestoneWrapper .column-div span {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.61);
}
.customDialog .sidebarMilestoneWrapper .column-div text {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 13px;
}
.customDialog .sidebarMilestoneWrapper .milestoneProgress {
  margin-top: 32px;
  margin-left: 16px;
}
.customDialog .sidebarMilestoneWrapper .milestoneProgress span {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 13px;
}
.customDialog .sidebarMilestoneWrapper .milestone {
  margin-top: 16px;
  text-align: right;
}
.customDialog .sidebarMilestoneWrapper .milestone span {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  padding-left: 16px;
  margin-left: 50px;
  color: #108EE9;
  cursor: pointer;
}
.customDialog .divMilestoneHeaders {
  color: black;
  font-family: "Sofia Pro", sans-serif;
  font-weight: 400;
  padding-top: 16px;
  padding-left: 20px;
  font-size: 16px;
}
.customDialog .sidebarCustomCalender {
  width: 50%;
  margin-bottom: 6px;
  margin-top: 6px;
  padding: 6px 0;
  background-color: rgba(0, 0, 0, 0.08);
}
.customDialog .sidebarCustomCalender .react-date-picker__wrapper {
  border: none;
}
.customDialog .sidebarCustomCalender select.react-date-picker__inputGroup__input {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 0.9375rem;
  pointer-events: none;
  width: 30px;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}
.customDialog .sidebarCustomCalender select.react-date-picker__inputGroup__input:focus {
  outline: none;
}
.customDialog .sidebarCustomCalender select::-ms-expand.react-date-picker__inputGroup__input {
  display: none;
}
.customDialog .sidebarCustomCalender .react-date-picker__inputGroup__day {
  width: 18px !important;
  margin-left: -8px;
}
.customDialog .sidebarCustomCalender .react-date-picker__inputGroup__day:focus {
  outline: none;
}
.customDialog .sidebarCustomCalender .react-date-picker__inputGroup__day::selection {
  color: #000000;
  background-color: transparent;
}
.customDialog .sidebarCustomCalender .react-date-picker__inputGroup__month {
  width: 32px !important;
}
.customDialog .sidebarCustomCalender .react-date-picker__inputGroup__year {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.customDialog .sidebarCustomCalender .react-date-picker__inputGroup__year:focus {
  outline: none;
}
.customDialog .sidebarCustomCalender .react-date-picker__inputGroup__year::selection {
  color: #000000;
  background-color: transparent;
}
.customDialog .highPen {
  margin: 8px 4px 0 4px;
}
.customDialog .customMultipleSelect[aria-owns=customized-multiple-select-hook-popup] {
  background-color: rgba(0, 0, 0, 0.05);
}
.customDialog .customMultipleSelect > div {
  width: 100%;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.customDialog .customMultipleSelect > div:focus {
  outline: none;
}
.customDialog .customMultipleSelect > div.focused {
  border: none;
  box-shadow: none;
}
.customDialog .customMultipleSelect > div input {
  background-color: transparent;
  padding: 0 0 0 5px;
}
.customDialog .customMultipleSelect > div > div {
  height: 24px;
  margin: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-sizing: content-box;
  padding: 3px 5px 0 0;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper {
  width: 100%;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput {
  width: 56%;
  margin-right: 32px;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput:before {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput:after {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput:hover {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput:hover:before {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput input {
  font-family: "Sofia Pro", "inherit";
  font-size: 14px;
  font-weight: 500;
  color: #7B7B7B;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskmax {
  width: 15%;
  text-align: center;
  font-family: "Sofia Pro", "inherit";
  font-size: 14px;
  font-weight: 500;
  color: #7B7B7B;
  margin-right: 40px;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskmax:before {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskmax:after {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskmax:hover {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskmax:hover:before {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskvalue {
  width: 8%;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskvalue:before {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskvalue:after {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskvalue:hover {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskvalue:hover:before {
  border-bottom: none;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskvalue input {
  font-family: "Sofia Pro", "inherit";
  font-size: 14px;
  font-weight: 500;
  color: #7B7B7B;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskCheckbox {
  padding: 5px;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskCheckbox svg {
  width: 18px;
  height: 18px;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskRemove {
  right: 5px;
  position: absolute;
  font-size: 18px;
  color: rgba(73, 121, 255, 0.85);
  display: none;
  cursor: pointer;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper .subtaskDetails {
  right: 32px;
  position: absolute;
  font-size: 18px;
  color: rgba(73, 121, 255, 0.85);
  display: none;
  cursor: pointer;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper > ul {
  width: 100%;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper > ul li {
  padding-top: 10px;
  padding-bottom: 10px;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper > ul li:hover {
  background-color: #d7e4fe;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper > ul li:hover .subtaskRemove {
  display: block;
}
.customDialog .sidebarSubtaskWrapper .subTaskWrapper > ul li:hover .subtaskDetails {
  display: block;
}
.customDialog .customSingleSelect {
  padding: 2px 0;
  margin-bottom: 6px;
  background-color: rgba(0, 0, 0, 0.08);
}
.customDialog .customSingleSelect[aria-owns=customized-single-select-hook-popup] {
  background-color: rgba(0, 0, 0, 0.05);
}
.customDialog .customSingleSelect input {
  width: auto;
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: inherit;
}
.customDialog .editHoverBGColor {
  padding-left: 0px;
  background-color: rgba(0, 0, 0, 0.08);
}
.customDialog .editHoverBGColor:hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.11);
}
.customDialog .hoverBGColor {
  padding-left: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
.customDialog .hoverBGColor:hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.11);
}

.MuiMenuItem-root {
  font-family: "Sofia Pro", sans-serif !important;
  font-weight: 300;
}

.customMemberDialog .MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.71);
}
.customMemberDialog .MuiPaper-root {
  margin: 0;
  max-height: 100%;
  position: fixed;
  border-radius: 0;
  width: 60%;
  background: #f7f7f7;
}
.customMemberDialog .MuiDialogTitle-root {
  font-family: "Sofia Pro", sans-serif;
  font-size: 1.5rem;
}
.customMemberDialog .MuiDialogTitle-root textarea {
  width: 100%;
  border: 0;
  color: white;
  background-color: transparent;
  min-height: 35px;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
}
.customMemberDialog .MuiDialogTitle-root textarea:focus, .customMemberDialog .MuiDialogTitle-root textarea:active {
  outline: none;
  border-bottom: 0px solid #ccc677;
}
.customMemberDialog .MuiDialogTitle-root textarea:hover {
  border-bottom: 0px solid #e6e3b6;
}
.customMemberDialog .MuiDialogTitle-root textarea::-webkit-input-placeholder {
  color: #ffffff;
}
.customMemberDialog .MuiDialogTitle-root textarea:-ms-input-placeholder {
  color: #ffffff;
}
.customMemberDialog .MuiDialogTitle-root textarea::-ms-input-placeholder {
  color: #ffffff;
}
.customMemberDialog .MuiDialogTitle-root textarea::placeholder {
  color: #ffffff;
}
.customMemberDialog .MuiDialogTitle-root .dialogDrawerClose {
  color: #ffffff;
  cursor: pointer;
  padding: 2px;
  height: 24px;
  width: 24px;
  margin-top: 5px;
  margin-right: -10px;
}
.customMemberDialog .MuiDialogTitle-root .dialogDrawerClose:hover {
  transition: all 0.2s;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
}
.customMemberDialog .MuiDialogContent-root {
  flex: 1 1 auto;
  padding: 8px 0px 32px;
  overflow: hidden;
  background-color: white;
}
.customMemberDialog .MuiDialogContent-root span {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 0.9375rem;
}
.customMemberDialog .MuiDialogContent-root textarea {
  width: 100%;
  border: 0;
  background-color: rgba(0, 0, 0, 0.08);
  font-family: "Sofia Pro", "inherit";
  padding-top: 5px;
  padding-left: 10px;
  font-size: 15px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
  min-height: 35px;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
}
.customMemberDialog .MuiDialogContent-root textarea:hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.11);
}
.customMemberDialog .MuiDialogContent-root textarea::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.customMemberDialog .MuiDialogContent-root textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.customMemberDialog .MuiDialogContent-root textarea::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.customMemberDialog .MuiDialogContent-root textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.customMemberDialog .MuiDialogContent-root .divHeaders {
  color: #000000;
  font-family: "Sofia Pro", sans-serif;
  font-weight: 400;
  padding-top: 16px;
  padding-left: 32px;
  font-size: 16px;
}
.customMemberDialog .MuiDialogContent-root .divContent {
  overflow: hidden;
  height: 630px;
}
.customMemberDialog .MuiDialogContent-root .divContent:hover {
  overflow-y: auto;
}
.customMemberDialog .MuiDialogContent-root .divLists {
  color: rgba(0, 0, 0, 0.51);
  font-family: "Sofia Pro", sans-serif;
  width: 55%;
  font-weight: normal;
  padding-top: 3px;
  padding-left: 18px;
  font-size: 14px;
}
.customMemberDialog .MuiDialogContent-root .divListStatus {
  color: rgba(0, 0, 0, 0.51);
  font-family: "Sofia Pro", sans-serif;
  font-weight: normal;
  padding-top: 3px;
  padding-left: 10px;
  width: 86px;
  font-size: 14px;
}
.customMemberDialog .MuiDialogContent-root .divColor {
  height: 16px;
  width: 16px;
  margin-top: 4px;
  margin-left: 24px;
  border-radius: 50%;
}
.customMemberDialog .MuiDialogContent-root .editIcon {
  margin-top: 2px;
  margin-left: 55px;
  padding: 6px;
  height: 32px;
  width: 32px;
  color: rgba(0, 0, 0, 0.51);
}
.customMemberDialog .MuiDialogContent-root .editIcon:hover {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
}
.customMemberDialog .MuiDialogContent-root .deleteIcon {
  margin-top: 2px;
  margin-left: 10px;
  padding: 6px;
  height: 32px;
  width: 32px;
  color: rgba(0, 0, 0, 0.51);
}
.customMemberDialog .MuiDialogContent-root .deleteIcon:hover {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
}
.customMemberDialog .MuiDialogContent-root .sidebarIcon {
  display: inline-flex;
  vertical-align: top;
  margin-right: 20px;
}
.customMemberDialog .MuiDialogContent-root .sidebarIcon svg {
  width: 20px;
  height: 20px;
  color: #A0A0A0;
}
.customMemberDialog .MuiDialogContent-root .sidebarTitle {
  color: #7B7B7B;
}
.customMemberDialog .MuiDialogContent-root .sidebarControllerRow {
  margin: 5px 0;
  padding-left: 20px;
  padding-right: 20px;
}
.customMemberDialog .MuiDialogContent-root .sidebarControllerRow .sidebarProjectColor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  vertical-align: bottom;
  margin-right: 20px;
  font-size: 8px;
  justify-content: center;
  align-items: center;
}
.customMemberDialog .MuiDialogContent-root .sidebarControllerRow .sidebarProjectName {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 0.9375rem;
}
.customMemberDialog .MuiDialogContent-root .sidebarControllerRow .sidebarProjectName input {
  padding-left: 10px;
}
.customMemberDialog .MuiDialogContent-root .sidebarControllerRow .timePerDay {
  width: 100%;
  padding: 2px 4px;
  margin-bottom: 6px;
  background-color: rgba(0, 0, 0, 0.08);
}
.customMemberDialog .MuiDialogContent-root .sidebarControllerRow .timePerDay .mui-focused {
  border-bottom: none !important;
}
.customMemberDialog .MuiDialogContent-root .sidebarControllerRow .timePerDay .MuiInput-underline:before {
  border-bottom: 0;
}
.customMemberDialog .MuiDialogContent-root .sidebarControllerRow .timePerDay .MuiInput-underline:after {
  border-bottom: 0;
}
.customMemberDialog .MuiDialogContent-root .sidebarControllerRow .timePerDay input {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  font-size: 0.9375rem;
  padding-left: 3px;
  padding-top: 6px;
}
.customMemberDialog .MuiFormLabel-root {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
}
.customMemberDialog .MuiInputBase-root {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  padding-top: 5px;
  padding-bottom: 5px;
}
.customMemberDialog .msg {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  padding: 5px 0 5px 5px;
  margin-left: 56px;
  cursor: pointer;
  color: red;
}
.customMemberDialog .msg-green {
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
  padding: 5px 0 5px 5px;
  margin-left: 56px;
  cursor: pointer;
  color: green;
}
.customMemberDialog .customMultipleSelect[aria-owns=customized-multiple-select-hook-popup] {
  background-color: rgba(0, 0, 0, 0.05);
}
.customMemberDialog .customMultipleSelect > div {
  width: 100%;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.customMemberDialog .customMultipleSelect > div:focus {
  outline: none;
}
.customMemberDialog .customMultipleSelect > div.focused {
  border: none;
  box-shadow: none;
}
.customMemberDialog .customMultipleSelect > div input {
  background-color: transparent;
  padding: 0 0 0 5px;
}
.customMemberDialog .customMultipleSelect > div > div {
  height: 24px;
  margin: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-sizing: content-box;
  padding: 3px 5px 0 0;
}
.customMemberDialog .customSingleSelect {
  padding: 2px 0;
  margin-bottom: 6px;
  background-color: rgba(0, 0, 0, 0.08);
}
.customMemberDialog .customSingleSelect[aria-owns=customized-single-select-hook-popup] {
  background-color: rgba(0, 0, 0, 0.05);
}
.customMemberDialog .customSingleSelect input {
  width: auto;
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: inherit;
}
.customMemberDialog .editHoverBGColor {
  padding-left: 0px;
  background-color: rgba(0, 0, 0, 0.08);
}
.customMemberDialog .editHoverBGColor:hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.11);
}
.customMemberDialog .hoverBGColor {
  padding-left: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
.customMemberDialog .hoverBGColor:hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.11);
}

/*# sourceMappingURL=ProjectDialog.css.map */

.customDrawer .MuiBackdrop-root {
  background-color: transparent; }
.customDrawer .MuiPaper-root {
  margin: 0;
  max-height: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  border-radius: 0;
  width: 500px; }
.customDrawer .MuiDialogTitle-root {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem; }
  .customDrawer .MuiDialogTitle-root textarea {
    width: 95%;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word; }
    .customDrawer .MuiDialogTitle-root textarea:focus, .customDrawer .MuiDialogTitle-root textarea:active {
      outline: none;
      border-bottom: 0px solid #ccc677; }
    .customDrawer .MuiDialogTitle-root textarea:hover {
      border-bottom: 0px solid #e6e3b6; }
  .customDrawer .MuiDialogTitle-root .customDrawerClose {
    color: #A0A0A0;
    cursor: pointer;
    padding: 2px;
    height: 24px;
    width: 24px;
    margin-top: 5px;
    margin-right: -10px; }
    .customDrawer .MuiDialogTitle-root .customDrawerClose:hover {
      transition: all 0.2s;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.05); }
.customDrawer .MuiDialogContent-root {
  background-color: white; }
  .customDrawer .MuiDialogContent-root span {
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 300;
    font-size: 0.9375rem; }
  .customDrawer .MuiDialogContent-root .sidebarIcon {
    display: inline-flex;
    vertical-align: top;
    margin-right: 20px; }
    .customDrawer .MuiDialogContent-root .sidebarIcon svg {
      width: 20px;
      height: 20px;
      color: #A0A0A0; }
  .customDrawer .MuiDialogContent-root .weekendIcon {
    display: inline-flex;
    vertical-align: top;
    margin-left: -8px; }
    .customDrawer .MuiDialogContent-root .weekendIcon svg {
      width: 20px;
      height: 20px;
      color: #A0A0A0; }
  .customDrawer .MuiDialogContent-root .weekendTitle {
    margin-top: -2px;
    color: #7B7B7B;
    cursor: pointer; }
  .customDrawer .MuiDialogContent-root .sidebarTitle {
    color: #7B7B7B; }
  .customDrawer .MuiDialogContent-root .sidebarControllerRow {
    margin: 5px 0;
    padding-left: 20px;
    padding-right: 20px; }
    .customDrawer .MuiDialogContent-root .sidebarControllerRow .sidebarProjectColor {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-flex;
      vertical-align: bottom;
      margin-right: 20px;
      font-size: 8px;
      justify-content: center;
      align-items: center; }
    .customDrawer .MuiDialogContent-root .sidebarControllerRow .sidebarProjectName {
      font-family: 'Sofia Pro', sans-serif;
      font-weight: 300;
      font-size: 0.9375rem; }
      .customDrawer .MuiDialogContent-root .sidebarControllerRow .sidebarProjectName input {
        padding-left: 10px; }
    .customDrawer .MuiDialogContent-root .sidebarControllerRow .timePerDay {
      width: 100%;
      padding: 2px 4px;
      margin-bottom: 4px;
      background-color: rgba(0, 0, 0, 0.08); }
      .customDrawer .MuiDialogContent-root .sidebarControllerRow .timePerDay .mui-focused {
        border-bottom: none !important; }
      .customDrawer .MuiDialogContent-root .sidebarControllerRow .timePerDay .MuiInput-underline:before {
        border-bottom: 0; }
      .customDrawer .MuiDialogContent-root .sidebarControllerRow .timePerDay .MuiInput-underline:after {
        border-bottom: 0; }
      .customDrawer .MuiDialogContent-root .sidebarControllerRow .timePerDay input {
        font-family: 'Sofia Pro', sans-serif;
        font-weight: 300;
        font-size: 0.9375rem;
        padding-left: 3px;
        padding-top: 6px; }

.sidebarDescriptionWrapper {
  margin-top: 30px;
  margin-bottom: 30px; }
  .sidebarDescriptionWrapper textarea {
    border: none;
    padding-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    resize: none;
    font-family: "Sofia Pro", 'inherit';
    font-size: 15px;
    font-weight: 300;
    color: #707070; }
    .sidebarDescriptionWrapper textarea:focus {
      outline: none; }

.sidebarCustomCalender {
  width: 50%;
  margin-bottom: 4px;
  padding: 5px 0;
  background-color: rgba(0, 0, 0, 0.08); }
  .sidebarCustomCalender .react-date-picker__wrapper {
    border: none; }
  .sidebarCustomCalender select.react-date-picker__inputGroup__input {
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 300;
    font-size: 0.9375rem;
    pointer-events: none;
    width: 30px;
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none; }
    .sidebarCustomCalender select.react-date-picker__inputGroup__input:focus {
      outline: none; }
  .sidebarCustomCalender select::-ms-expand.react-date-picker__inputGroup__input {
    display: none; }
  .sidebarCustomCalender .react-date-picker__inputGroup__day {
    width: 18px !important;
    margin-left: -8px; }
    .sidebarCustomCalender .react-date-picker__inputGroup__day:focus {
      outline: none; }
    .sidebarCustomCalender .react-date-picker__inputGroup__day::selection {
      color: #000000;
      background-color: transparent; }
  .sidebarCustomCalender .react-date-picker__inputGroup__month {
    width: 32px !important; }
  .sidebarCustomCalender .react-date-picker__inputGroup__year {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important; }
    .sidebarCustomCalender .react-date-picker__inputGroup__year:focus {
      outline: none; }
    .sidebarCustomCalender .react-date-picker__inputGroup__year::selection {
      color: #000000;
      background-color: transparent; }

.highPen {
  margin: 4px 4px 0 4px; }

.customMultipleSelect[aria-owns="customized-multiple-select-hook-popup"] {
  background-color: rgba(0, 0, 0, 0.05); }
.customMultipleSelect > div {
  width: 100%;
  border: none;
  border-radius: 0;
  background-color: transparent; }
  .customMultipleSelect > div:focus {
    outline: none; }
  .customMultipleSelect > div.focused {
    border: none;
    box-shadow: none; }
  .customMultipleSelect > div input {
    background-color: transparent;
    padding: 0 0 0 5px; }
  .customMultipleSelect > div > div {
    height: 24px;
    margin: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-sizing: content-box;
    padding: 3px 5px 0 0; }

.sidebarSubtaskWrapper .subTaskWrapper {
  width: 100%; }
  .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput {
    width: 100%; }
    .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput:before {
      border-bottom: none; }
    .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput:after {
      border-bottom: none; }
    .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput:hover {
      border-bottom: none; }
      .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput:hover:before {
        border-bottom: none; }
    .sidebarSubtaskWrapper .subTaskWrapper .subtaskInput input {
      font-family: "Sofia Pro", 'inherit';
      font-size: 14px;
      font-weight: 500;
      color: #7B7B7B; }
  .sidebarSubtaskWrapper .subTaskWrapper .MuiList-padding {
    margin-left: -6px; }
  .sidebarSubtaskWrapper .subTaskWrapper .MuiListItem-gutters {
    padding-right: 0px !important;
    padding-left: 0px !important; }
  .sidebarSubtaskWrapper .subTaskWrapper .subtaskInputChecked {
    width: 100%; }
    .sidebarSubtaskWrapper .subTaskWrapper .subtaskInputChecked:before {
      border-bottom: none; }
    .sidebarSubtaskWrapper .subTaskWrapper .subtaskInputChecked:after {
      border-bottom: none; }
    .sidebarSubtaskWrapper .subTaskWrapper .subtaskInputChecked:hover {
      border-bottom: none; }
      .sidebarSubtaskWrapper .subTaskWrapper .subtaskInputChecked:hover:before {
        border-bottom: none; }
    .sidebarSubtaskWrapper .subTaskWrapper .subtaskInputChecked input {
      font-family: "Sofia Pro", 'inherit';
      font-size: 14px;
      font-weight: 500;
      color: #7B7B7B;
      text-decoration: line-through; }
  .sidebarSubtaskWrapper .subTaskWrapper .subtaskCheckbox {
    padding: 5px; }
    .sidebarSubtaskWrapper .subTaskWrapper .subtaskCheckbox svg {
      width: 18px;
      height: 18px; }
  .sidebarSubtaskWrapper .subTaskWrapper .subtaskRemove {
    font-size: 18px;
    color: #A0A0A0;
    display: none;
    cursor: pointer; }
  .sidebarSubtaskWrapper .subTaskWrapper > ul {
    width: 100%; }
    .sidebarSubtaskWrapper .subTaskWrapper > ul li {
      padding-top: 0;
      padding-bottom: 0; }
      .sidebarSubtaskWrapper .subTaskWrapper > ul li:hover {
        background-color: rgba(0, 0, 0, 0.05); }
        .sidebarSubtaskWrapper .subTaskWrapper > ul li:hover .subtaskRemove {
          display: block; }

.MuiDialogActions-root {
  background-color: white; }

.customSingleSelect {
  padding: 2px 0;
  margin-bottom: 4px;
  background-color: rgba(0, 0, 0, 0.08); }
  .customSingleSelect[aria-owns="customized-single-select-hook-popup"] {
    background-color: rgba(0, 0, 0, 0.05); }
  .customSingleSelect input {
    width: auto;
    outline: none;
    border: none;
    background-color: transparent;
    font-weight: inherit; }

.editHoverBGColor {
  padding-left: 0px; }
  .editHoverBGColor:hover {
    transition: all 0.2s;
    background-color: rgba(0, 0, 0, 0.11); }

.hoverBGColor {
  padding-left: 4px; }
  .hoverBGColor:hover {
    transition: all 0.2s;
    background-color: rgba(0, 0, 0, 0.11); }

/*# sourceMappingURL=EventSidebar.css.map */
.ant-popover {
  background-color: rgba(255, 255, 255, 0); }
  .ant-popover .ant-popover-inner {
    border-radius: 0; }
    .ant-popover .ant-popover-inner .ant-popover-inner-content {
      padding: 0; }

.customPopover .customPopoverItemRow {
  padding: 0 10px;
  font-family: "Sofia Pro",'inherit'; }
  .customPopover .customPopoverItemRow.customPopoverTask {
    padding-top: 5px; }
    .customPopover .customPopoverItemRow.customPopoverTask .header-text {
      font-weight: 300;
      font-size: 15px;
      color: #000000; }
  .customPopover .customPopoverItemRow.customPopoverProject {
    margin-top: 10px; }
    .customPopover .customPopoverItemRow.customPopoverProject .header4-text {
      font-weight: 300;
      font-size: 14px;
      color: #7B7B7B; }
  .customPopover .customPopoverItemRow.customPopoverTime {
    padding-bottom: 5px;
    margin-top: -4px; }
    .customPopover .customPopoverItemRow.customPopoverTime .customPopoverTime__Date {
      float: left; }
      .customPopover .customPopoverItemRow.customPopoverTime .customPopoverTime__Date > span {
        font-size: 14px;
        font-weight: 300;
        color: #7B7B7B; }
    .customPopover .customPopoverItemRow.customPopoverTime .customPopoverTime__Time {
      float: right;
      margin-right: -16px; }
      .customPopover .customPopoverItemRow.customPopoverTime .customPopoverTime__Time > span {
        font-size: 14px;
        font-weight: 300;
        color: #7B7B7B; }

/*# sourceMappingURL=Popover.css.map */
.MuiMenuItem-root a {
  font-family: "Sofia Pro", sans-serif;
  font-size: 12px;
}

.header1-text {
  font-size: 25px;
  color: #90CDF9;
  font-weight: 500;
}

.header-text {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
}

.header2-text {
  font-size: 14px;
  font-weight: 500;
  font-family: "Sofia Pro", sans-serif;
}

.header3-text {
  font-size: 12px;
  font-weight: 600;
  font-family: "Sofia Pro", sans-serif;
}

.header4-text {
  font-size: 14px;
  color: rgba(117, 117, 117, 0.86);
  font-weight: 500;
  font-family: "Sofia Pro", sans-serif;
}

.header5-text {
  font-size: 16px;
  color: rgba(117, 117, 117, 0.86);
  font-weight: 500;
  font-family: "Sofia Pro", sans-serif;
}

.base-text {
  font-size: 12px;
}

.help-text {
  font-size: 12px;
  color: #999;
}

.line {
  color: #F5F4E0;
  background-color: #F5F4E0;
  height: 6px;
  border: none;
}

.disabled-text {
  font-size: 12px;
  color: #ccc;
}

.scheduler {
  margin: 0;
  border-spacing: 0;
}

.scheduler-width-top {
  width: calc(100vw - 246px);
  display: flex;
}
@-moz-document url-prefix() {
  .scheduler-width-top {
    width: calc(100vw - 254px);
  }
}

.scheduler td {
  padding: 0px;
}

.expander-space {
  overflow: hidden;
  display: none;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 0.9em;
  vertical-align: middle;
  margin-top: -1%;
}

.resource-view {
  border: 1px solid #0000ff00;
  overflow: hidden;
  background: #ffffff;
  border-right-width: 0px !important;
  border-right-color: rgba(214, 214, 214, 0.51) !important;
}

.scheduler-view {
  border: 1px solid #e9e9e9;
  margin: 0 0 0 -1px;
  padding: 0;
  border-left-width: 20px !important;
  border-left-color: rgba(0, 0, 0, 0.09) !important;
}

.scheduler-content {
  position: relative;
  z-index: 2;
}

.scheduler-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

table.resource-table, table.scheduler-bg-table, table.scheduler-table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  text-align: center;
}

table.scheduler-table {
  border: 1px solid #e9e9e9;
}

table.scheduler-content-table {
  margin: 0;
  padding: 0;
  border: 0 solid #e9e9e9;
  border-spacing: 0;
}
table.scheduler-content-table tbody tr:first-child {
  display: none;
}

table.scheduler-bg-table tbody tr:first-child {
  display: none;
}

table.resource-table tbody {
  background-color: rgba(0, 0, 0, 0.04);
}
table.resource-table tbody tr:first-child {
  display: none;
}

table.resource-table tr, table.scheduler-bg-table tr, table.scheduler-table tr {
  border-bottom: 1px solid #e9e9e9;
}

table.resource-table th, table.scheduler-table th, table.resource-table td, table.scheduler-bg-table td, table.scheduler-table td {
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

table.scheduler-bg-table th {
  border-right: 1px solid #e9e9e9;
}

table.resource-table tr th:last-child, table.scheduler-bg-table tr th:last-child, table.scheduler-table tr th:last-child,
table.resource-table tr td:last-child, table.scheduler-bg-table tr td:last-child, table.scheduler-table tr td:last-child {
  border-right: 0;
}

table.resource-table tr:last-child td, table.scheduler-bg-table tr:last-child td, table.scheduler-table tr:last-child td {
  border-bottom: 0;
}

.timeline-event {
  position: absolute;
}

.day-event {
  position: relative;
  display: inline-block;
  margin: 0px 5px;
}

.day-event-container {
  text-align: left;
  padding: 5px 5px 0 5px;
}

.round-all {
  border-radius: 14px;
}

.round-head {
  border-radius: 14px 0px 0px 14px;
}

.round-tail {
  border-radius: 0px 14px 14px 0px;
}

.round-none {
  border-radius: 0px;
}

.event-container {
  position: relative;
  background-color: rgba(0, 0, 0, 0.04);
}

.event-item {
  margin: 1px 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  color: #fff;
  padding-right: 20px !important;
}

.overflow-text {
  overflow: hidden;
  white-space: nowrap;
  margin-left: -16px;
  padding-right: 0px !important;
  text-align: center !important;
  font-family: "Sofia Pro", sans-serif;
  font-weight: 300;
}

.status-dot {
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

.ant-radio-button-wrapper-checked {
  background-color: #108EE9;
  color: #FFFFFF;
}

.icon-nav:hover {
  color: #1E90FF !important;
  box-shadow: 0 0 0px !important;
  cursor: pointer;
}

.add-more-popover-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.popover-calendar {
  width: 300px;
}

.popover-calendar .ant-calendar {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0) !important;
}

.event-resizer {
  position: absolute;
  z-index: 4;
  display: block;
  width: 7px;
  top: -1px;
  bottom: -1px;
}

.event-start-resizer {
  cursor: w-resize;
  left: -1px;
}

.event-end-resizer {
  cursor: e-resize;
  right: -1px;
}

.selected-area {
  position: absolute;
}

.slot-cell {
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
}

.slot-text {
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  color: rgba(0, 0, 0, 0.51);
  font-weight: 400;
  text-align: center !important;
}

.slot-div {
  display: inline-block;
  align-items: center;
  text-align: center;
  padding-top: 15px;
}

.slot-text-round {
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  height: 48px;
  width: 48px;
  background: white;
}

.slot-span {
  padding-top: 22%;
  font-size: 16px !important;
  color: black;
  font-weight: 400;
}

.view-change {
  font-size: 14px;
  font-family: "Sofia Pro", sans-serif;
}

.view-select {
  border: none;
  font-size: 14px !important;
  font-family: "Sofia Pro", sans-serif !important;
  padding-right: 12px;
  padding-left: 12px;
  background: none;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}
.view-select:focus {
  outline: none;
}

.tt__event-item {
  font-family: "Sofia Pro", "inherit";
  font-weight: 100;
  padding-left: 6px;
  padding-right: 25px !important;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
}
.tt__event-item__fade {
  position: absolute;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 31%, rgba(255, 255, 255, 0.55) 100%);
  right: 0;
  top: 0;
  width: 30px;
  height: 43px;
  z-index: 1;
}
.tt__event-item__task-title {
  font-size: 15px;
  color: #000000;
  line-height: 16px;
  position: absolute;
  top: 3px;
  left: 6px;
}
.tt__event-item__task-title::after {
  content: "\a";
  white-space: pre;
}
.tt__event-item__project-title {
  font-size: 14px;
  color: #000000b0;
  line-height: 15px;
  position: absolute;
  left: 6px;
  bottom: 6px;
}
.tt__event-item__task-time {
  color: #000000;
  position: absolute;
  font-size: 14px;
  right: 0px;
  padding-top: 18px;
  padding-right: 5px;
  text-align: right;
  width: 29.5156240463px;
  height: 43px;
  bottom: 0px;
  z-index: 2;
  font-weight: 500;
}

.scheduler-bg {
  top: 15px !important;
}
.scheduler-bg .weekEnd {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAABqADAAQAAAABAAAABgAAAABIWB+fAAAAOElEQVQIHWNkQAMNDQ1Mz549Y2ZCFndwcGAB8tlmzZr1Gy4OEgSq5oALgBjEC4JUh4aGMoNodAAAeDYPtP2tR3oAAAAASUVORK5CYII=);
  background-repeat: repeat;
}

.weekEndDay {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAcSURBVHgBrcghAQAAAMIw+ud6L0CjmZw0AN+jAl9DEHjaMtH5AAAAAElFTkSuQmCC);
  background-repeat: repeat;
}

/*# sourceMappingURL=Timeline.css.map */

.scroller .header-body {
  display: flex;
  padding: 16px;
}
.scroller .header-body textarea {
  width: 45%;
  border: 0;
  justify-content: center;
  background-color: transparent;
  min-height: 35px;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: -3px;
  padding-top: 4px;
  padding-left: 2px;
}
.scroller .header-body textarea:focus, .scroller .header-body textarea:active {
  outline: none;
  border-bottom: 0px solid #c9c9c9;
}
.scroller .header-body textarea:hover {
  border: 1px solid #c9c9c9;
}
.scroller .header-body .header-project {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-top: -6px;
  color: #108EE9;
}
.scroller .header-body .header-project span {
  margin-left: 8px;
  font-size: 14px;
  width: 220px;
}
.scroller .header-body .header-project svg {
  height: 24px;
  width: 24px;
}
.scroller .header-body .header-project:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.scroller .header-body .header-timer {
  display: flex;
  position: absolute;
  right: 48px;
  margin-top: 2px;
}
.scroller .header-body .header-timer .play-pause {
  display: flex;
  cursor: pointer;
  margin-left: 48px;
  align-items: center;
  margin-top: -4px;
  color: #108EE9;
}
.scroller .header-body .header-timer .play-pause svg {
  height: 32px;
  width: 32px;
  padding: 5px;
}
.scroller .header-body .header-timer .play-pause svg:hover {
  background: #eeeeee;
  border-radius: 50%;
  color: #5765b3;
}
.scroller .header-body .header-estimate {
  display: flex;
  margin-left: 24px;
}
.scroller .header-body .header-estimate span {
  margin-top: -3px;
  padding-top: 5px;
  padding-left: 8px;
}
.scroller .header-body .header-estimate textarea {
  display: flex;
  width: 64px;
  border: 0;
  background-color: transparent;
  min-height: 35px;
  justify-content: center;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-left: 8px;
  margin-top: -3px;
  padding-top: 5px;
  padding-left: 8px;
}
.scroller .header-body .header-estimate textarea:focus, .scroller .header-body .header-estimate textarea:active {
  outline: none;
  border-bottom: 0px solid #c9c9c9;
}
.scroller .header-body .header-estimate textarea:hover {
  border: 1px solid #c9c9c9;
}
.scroller .this-week {
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
  font-family: "Sofia Pro", sans-serif;
  font-size: 15px;
  display: flex;
  cursor: pointer;
}
.scroller .this-week .week-total {
  position: absolute;
  right: 130px;
}
.scroller .this-week .total {
  position: absolute;
  right: 48px;
  font-size: 16px;
}
.scroller .this-week:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.scroller .date-name {
  display: flex;
  background: #7b7b7b3b;
  margin: 0px 0px 8px;
}
.scroller .date-name .spent {
  position: absolute;
  right: 33%;
  padding: 6px;
  font-size: 14px;
}
.scroller .date-name .estimate {
  position: absolute;
  right: 28%;
  padding: 6px;
  font-size: 14px;
}
.scroller .date-name span {
  padding: 8px;
  font-size: 12px;
  font-weight: lighter;
}
.scroller .date-name .day-total {
  position: absolute;
  right: 32px;
  padding: 4px;
}
.scroller .week-row {
  display: flex;
  padding: 16px;
  border: 1px solid #c9c9c9;
  margin-right: -16px;
  margin-left: -16px;
}
.scroller .week-row textarea {
  width: 45%;
  border: 0;
  justify-content: center;
  background-color: transparent;
  min-height: 35px;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-left: 16px;
  margin-top: -3px;
  padding-top: 4px;
  padding-left: 2px;
}
.scroller .week-row textarea:focus, .scroller .week-row textarea:active {
  outline: none;
  border-bottom: 0px solid #c9c9c9;
}
.scroller .week-row textarea:hover {
  border: 1px solid #c9c9c9;
}
.scroller .week-row .header-project {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-top: -6px;
  color: #108EE9;
}
.scroller .week-row .header-project span {
  margin-left: 8px;
  font-size: 14px;
  width: 220px;
}
.scroller .week-row .header-project svg {
  height: 16px;
  width: 16px;
}
.scroller .week-row .header-project:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.scroller .week-row .header-timer {
  display: flex;
  position: absolute;
  right: 48px;
  margin-top: 2px;
}
.scroller .week-row .header-timer .play-pause {
  display: flex;
  cursor: pointer;
  margin-left: 48px;
  align-items: center;
  margin-top: -4px;
  color: #108EE9;
}
.scroller .week-row .header-timer .play-pause svg {
  height: 32px;
  width: 32px;
  padding: 5px;
}
.scroller .week-row .header-timer .play-pause svg:hover {
  background: #eeeeee;
  border-radius: 50%;
  color: #5765b3;
}
.scroller .week-row .header-estimate {
  display: flex;
  margin-left: 24px;
}
.scroller .week-row .header-estimate span {
  margin-top: -3px;
  padding-top: 5px;
  padding-left: 8px;
}
.scroller .week-row .header-estimate textarea {
  display: flex;
  width: 64px;
  border: 0;
  background-color: transparent;
  min-height: 35px;
  justify-content: center;
  text-align: center;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-left: 8px;
  margin-top: -3px;
  padding-top: 5px;
  padding-left: 8px;
}
.scroller .week-row .header-estimate textarea:focus, .scroller .week-row .header-estimate textarea:active {
  outline: none;
  border-bottom: 0px solid #c9c9c9;
}
.scroller .week-row .header-estimate textarea:hover {
  border: 1px solid #c9c9c9;
}
.scroller .MuiCardContent-root {
  padding: 0px !important;
}
.scroller .MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}
.scroller .MuiPaper-root {
  box-shadow: none !important;
}
.scroller .MuiPaper-elevation1 {
  border: 1px solid #c9c9c9;
}
.scroller .MuiPaper-rounded {
  border-radius: 1px !important;
}
.scroller .MuiCardContent-root {
  font-family: "Sofia Pro";
  font-size: 16px;
}

@media (max-width: 3840px) {
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 44.8%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 42.4%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
}
@media (max-width: 3440px) {
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 43.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 40.7%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
}
@media (max-width: 2560px) {
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 39.3%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 35.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
}
@media (max-width: 2048px) {
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 34.8%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 30%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
}
@media (max-width: 1920px) {
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 33.2%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 28.2%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
}
@media (max-width: 1680px) {
  .scroller .header-body {
    display: flex;
    padding: 16px;
  }
  .scroller .header-body textarea {
    width: 50%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .header-body textarea:focus, .scroller .header-body textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .header-body .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .header-body .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 150px;
  }
  .scroller .header-body .header-project svg {
    height: 24px;
    width: 24px;
  }
  .scroller .header-body .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .header-body .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 2px;
  }
  .scroller .header-body .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .header-body .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .header-body .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .header-body .header-estimate {
    display: flex;
    margin-left: 24px;
  }
  .scroller .header-body .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea:focus, .scroller .header-body .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .this-week {
    margin-top: 24px;
    margin-left: 32px;
    margin-right: 32px;
    font-family: "Sofia Pro", sans-serif;
    font-size: 15px;
    display: flex;
    cursor: pointer;
  }
  .scroller .this-week .week-total {
    position: absolute;
    right: 130px;
  }
  .scroller .this-week .total {
    position: absolute;
    right: 48px;
    font-size: 16px;
  }
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 29.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 24.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
  .scroller .week-row {
    display: flex;
    padding: 16px;
    border: 1px solid #c9c9c9;
    margin-right: -16px;
    margin-left: -16px;
  }
  .scroller .week-row textarea {
    width: 50%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 16px;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .week-row textarea:focus, .scroller .week-row textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .week-row .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .week-row .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 150px;
  }
  .scroller .week-row .header-project svg {
    height: 16px;
    width: 16px;
  }
  .scroller .week-row .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .week-row .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 2px;
  }
  .scroller .week-row .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .week-row .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .week-row .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .week-row .header-estimate {
    display: flex;
    margin-left: 24px;
  }
  .scroller .week-row .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea:focus, .scroller .week-row .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
}
@media (max-width: 1600px) {
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 28.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 22.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
}
@media (max-width: 1536px) {
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 27.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 20.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
}
@media (max-width: 1440px) {
  .scroller .header-body {
    display: flex;
    padding: 16px;
  }
  .scroller .header-body textarea {
    width: 40%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .header-body textarea:focus, .scroller .header-body textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .header-body .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .header-body .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 120px;
  }
  .scroller .header-body .header-project svg {
    height: 24px;
    width: 24px;
  }
  .scroller .header-body .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .header-body .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 2px;
  }
  .scroller .header-body .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .header-body .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .header-body .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .header-body .header-estimate {
    display: flex;
    margin-left: 24px;
  }
  .scroller .header-body .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea:focus, .scroller .header-body .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .this-week {
    margin-top: 24px;
    margin-left: 32px;
    margin-right: 32px;
    font-family: "Sofia Pro", sans-serif;
    font-size: 15px;
    display: flex;
    cursor: pointer;
  }
  .scroller .this-week .week-total {
    position: absolute;
    right: 130px;
  }
  .scroller .this-week .total {
    position: absolute;
    right: 48px;
    font-size: 16px;
  }
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 37.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 30%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
  .scroller .week-row {
    display: flex;
    padding: 16px;
    border: 1px solid #c9c9c9;
    margin-right: -16px;
    margin-left: -16px;
  }
  .scroller .week-row textarea {
    width: 40%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 16px;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .week-row textarea:focus, .scroller .week-row textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .week-row .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .week-row .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 120px;
  }
  .scroller .week-row .header-project svg {
    height: 16px;
    width: 16px;
  }
  .scroller .week-row .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .week-row .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 2px;
  }
  .scroller .week-row .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .week-row .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .week-row .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .week-row .header-estimate {
    display: flex;
    margin-left: 24px;
  }
  .scroller .week-row .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea:focus, .scroller .week-row .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
}
@media (max-width: 1366px) {
  .scroller .header-body {
    display: flex;
    padding: 16px;
  }
  .scroller .header-body textarea {
    width: 40%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .header-body textarea:focus, .scroller .header-body textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .header-body .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .header-body .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 96px;
  }
  .scroller .header-body .header-project svg {
    height: 24px;
    width: 24px;
  }
  .scroller .header-body .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .header-body .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 2px;
  }
  .scroller .header-body .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .header-body .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .header-body .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .header-body .header-estimate {
    display: flex;
    margin-left: 24px;
  }
  .scroller .header-body .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea:focus, .scroller .header-body .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .this-week {
    margin-top: 24px;
    margin-left: 32px;
    margin-right: 32px;
    font-family: "Sofia Pro", sans-serif;
    font-size: 15px;
    display: flex;
    cursor: pointer;
  }
  .scroller .this-week .week-total {
    position: absolute;
    right: 130px;
  }
  .scroller .this-week .total {
    position: absolute;
    right: 48px;
    font-size: 16px;
  }
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 38.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 30.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
  .scroller .week-row {
    display: flex;
    padding: 16px;
    border: 1px solid #c9c9c9;
    margin-right: -16px;
    margin-left: -16px;
  }
  .scroller .week-row textarea {
    width: 40%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 16px;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .week-row textarea:focus, .scroller .week-row textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .week-row .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .week-row .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 96px;
  }
  .scroller .week-row .header-project svg {
    height: 16px;
    width: 16px;
  }
  .scroller .week-row .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .week-row .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 2px;
  }
  .scroller .week-row .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .week-row .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .week-row .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .week-row .header-estimate {
    display: flex;
    margin-left: 24px;
  }
  .scroller .week-row .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea:focus, .scroller .week-row .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
}
@media (max-width: 1360px) {
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 38%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 30.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
}
@media (max-width: 1280px) {
  .scroller .header-body {
    display: flex;
    padding: 16px;
  }
  .scroller .header-body textarea {
    width: 40%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .header-body textarea:focus, .scroller .header-body textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .header-body .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .header-body .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 96px;
  }
  .scroller .header-body .header-project svg {
    height: 24px;
    width: 24px;
  }
  .scroller .header-body .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .header-body .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 2px;
  }
  .scroller .header-body .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .header-body .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .header-body .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .header-body .header-estimate {
    display: flex;
    margin-left: 24px;
  }
  .scroller .header-body .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea:focus, .scroller .header-body .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .this-week {
    margin-top: 24px;
    margin-left: 32px;
    margin-right: 32px;
    font-family: "Sofia Pro", sans-serif;
    font-size: 15px;
    display: flex;
    cursor: pointer;
  }
  .scroller .this-week .week-total {
    position: absolute;
    right: 130px;
  }
  .scroller .this-week .total {
    position: absolute;
    right: 48px;
    font-size: 16px;
  }
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 36.5%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 28%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
  .scroller .week-row {
    display: flex;
    padding: 16px;
    border: 1px solid #c9c9c9;
    margin-right: -16px;
    margin-left: -16px;
  }
  .scroller .week-row textarea {
    width: 40%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 16px;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .week-row textarea:focus, .scroller .week-row textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .week-row .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .week-row .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 96px;
  }
  .scroller .week-row .header-project svg {
    height: 16px;
    width: 16px;
  }
  .scroller .week-row .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .week-row .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 2px;
  }
  .scroller .week-row .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .week-row .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .week-row .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .week-row .header-estimate {
    display: flex;
    margin-left: 24px;
  }
  .scroller .week-row .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea:focus, .scroller .week-row .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
}
@media (max-width: 1180px) {
  .scroller .header-body {
    display: flex;
    padding: 16px;
  }
  .scroller .header-body textarea {
    width: 30%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .header-body textarea:focus, .scroller .header-body textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .header-body .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .header-body .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 96px;
  }
  .scroller .header-body .header-project svg {
    height: 24px;
    width: 24px;
  }
  .scroller .header-body .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .header-body .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 2px;
  }
  .scroller .header-body .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .header-body .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .header-body .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .header-body .header-estimate {
    display: flex;
    margin-left: 24px;
  }
  .scroller .header-body .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea:focus, .scroller .header-body .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .this-week {
    margin-top: 24px;
    margin-left: 32px;
    margin-right: 32px;
    font-family: "Sofia Pro", sans-serif;
    font-size: 15px;
    display: flex;
    cursor: pointer;
  }
  .scroller .this-week .week-total {
    position: absolute;
    right: 130px;
  }
  .scroller .this-week .total {
    position: absolute;
    right: 48px;
    font-size: 16px;
  }
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 43%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 34%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
  .scroller .week-row {
    display: flex;
    padding: 16px;
    border: 1px solid #c9c9c9;
    margin-right: -16px;
    margin-left: -16px;
  }
  .scroller .week-row textarea {
    width: 30%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 16px;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .week-row textarea:focus, .scroller .week-row textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .week-row .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .week-row .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 96px;
  }
  .scroller .week-row .header-project svg {
    height: 16px;
    width: 16px;
  }
  .scroller .week-row .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .week-row .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 2px;
  }
  .scroller .week-row .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .week-row .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .week-row .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .week-row .header-estimate {
    display: flex;
    margin-left: 24px;
  }
  .scroller .week-row .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea:focus, .scroller .week-row .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
}
@media (max-width: 1024px) {
  .scroller .header-body {
    display: flex;
    padding: 16px;
  }
  .scroller .header-body textarea {
    width: 20%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .header-body textarea:focus, .scroller .header-body textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .header-body .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .header-body .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 96px;
  }
  .scroller .header-body .header-project svg {
    height: 24px;
    width: 24px;
  }
  .scroller .header-body .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .header-body .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 6px;
    align-items: center;
  }
  .scroller .header-body .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .header-body .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .header-body .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .header-body .header-estimate {
    display: flex;
    margin-left: 24px;
    margin-top: 6px;
  }
  .scroller .header-body .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .header-body .header-estimate textarea:focus, .scroller .header-body .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .header-body .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .this-week {
    margin-top: 24px;
    margin-left: 32px;
    margin-right: 32px;
    font-family: "Sofia Pro", sans-serif;
    font-size: 15px;
    display: flex;
    cursor: pointer;
  }
  .scroller .this-week .week-total {
    position: absolute;
    right: 130px;
  }
  .scroller .this-week .total {
    position: absolute;
    right: 48px;
    font-size: 16px;
  }
  .scroller .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scroller .date-name .spent {
    position: absolute;
    right: 47%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name .estimate {
    position: absolute;
    right: 36%;
    padding: 6px;
    font-size: 14px;
  }
  .scroller .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scroller .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
  .scroller .week-row {
    display: flex;
    padding: 16px;
    border: 1px solid #c9c9c9;
    margin-right: -16px;
    margin-left: -16px;
  }
  .scroller .week-row textarea {
    width: 20%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 16px;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scroller .week-row textarea:focus, .scroller .week-row textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scroller .week-row .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scroller .week-row .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 96px;
  }
  .scroller .week-row .header-project svg {
    height: 16px;
    width: 16px;
  }
  .scroller .week-row .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scroller .week-row .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 6px;
  }
  .scroller .week-row .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scroller .week-row .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scroller .week-row .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scroller .week-row .header-estimate {
    display: flex;
    margin-left: 24px;
    margin-top: 6px;
  }
  .scroller .week-row .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scroller .week-row .header-estimate textarea:focus, .scroller .week-row .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scroller .week-row .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
}

/*# sourceMappingURL=Hourlog.css.map */

.scrollers .MuiCardContent-root {
  padding: 0px !important;
}
.scrollers .MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}
.scrollers .MuiPaper-root {
  box-shadow: none !important;
}
.scrollers .MuiPaper-elevation1 {
  border: 1px solid #c9c9c9;
}
.scrollers .MuiPaper-rounded {
  border-radius: 1px !important;
}
.scrollers .MuiCardContent-root {
  font-family: "Sofia Pro";
  font-size: 16px;
}
.scrollers .this-week {
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
  font-family: "Sofia Pro", sans-serif;
  font-size: 15px;
  display: flex;
  cursor: pointer;
}
.scrollers .this-week .week-total {
  position: absolute;
  right: 130px;
}
.scrollers .this-week .total {
  position: absolute;
  right: 48px;
  font-size: 16px;
}
.scrollers .this-week:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.scrollers .date-name {
  display: flex;
  background: #7b7b7b3b;
  margin: 0px 0px 8px;
}
.scrollers .date-name .spent {
  position: absolute;
  right: 17%;
  padding: 6px;
  font-size: 14px;
}
.scrollers .date-name .estimate {
  position: absolute;
  right: 6%;
  padding: 6px;
  font-size: 14px;
}
.scrollers .date-name span {
  padding: 8px;
  font-size: 12px;
  font-weight: lighter;
}
.scrollers .date-name .day-total {
  position: absolute;
  right: 32px;
  padding: 4px;
}
.scrollers .week-row {
  display: flex;
  padding: 16px;
  border: 1px solid #c9c9c9;
  margin-right: -16px;
  margin-left: -16px;
}
.scrollers .week-row textarea {
  width: 45%;
  border: 0;
  justify-content: center;
  background-color: transparent;
  min-height: 35px;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-left: 16px;
  margin-top: -3px;
  padding-top: 4px;
  padding-left: 2px;
}
.scrollers .week-row textarea:focus, .scrollers .week-row textarea:active {
  outline: none;
  border-bottom: 0px solid #c9c9c9;
}
.scrollers .week-row textarea:hover {
  border: 1px solid #c9c9c9;
}
.scrollers .week-row .header-project {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-top: -6px;
  color: #108EE9;
}
.scrollers .week-row .header-project span {
  margin-left: 8px;
  font-size: 14px;
  width: 220px;
}
.scrollers .week-row .header-project svg {
  height: 16px;
  width: 16px;
}
.scrollers .week-row .header-project:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.scrollers .week-row .header-timer {
  display: flex;
  position: absolute;
  right: 48px;
  margin-top: 2px;
}
.scrollers .week-row .header-timer .play-pause {
  display: flex;
  cursor: pointer;
  margin-left: 48px;
  align-items: center;
  margin-top: -4px;
  color: #108EE9;
}
.scrollers .week-row .header-timer .play-pause svg {
  height: 32px;
  width: 32px;
  padding: 5px;
}
.scrollers .week-row .header-timer .play-pause svg:hover {
  background: #eeeeee;
  border-radius: 50%;
  color: #5765b3;
}
.scrollers .week-row .header-estimate {
  display: flex;
  margin-left: 24px;
}
.scrollers .week-row .header-estimate span {
  margin-top: -3px;
  padding-top: 5px;
  padding-left: 8px;
}
.scrollers .week-row .header-estimate textarea {
  display: flex;
  width: 64px;
  border: 0;
  background-color: transparent;
  min-height: 35px;
  justify-content: center;
  text-align: center;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-left: 8px;
  margin-top: -3px;
  padding-top: 5px;
  padding-left: 8px;
}
.scrollers .week-row .header-estimate textarea:focus, .scrollers .week-row .header-estimate textarea:active {
  outline: none;
  border-bottom: 0px solid #c9c9c9;
}
.scrollers .week-row .header-estimate textarea:hover {
  border: 1px solid #c9c9c9;
}

@media (max-width: 912px) {
  .makeStyles-paper-42 {
    min-width: 300px !important;
  }

  .scrollers .this-week {
    margin-top: 24px;
    margin-left: 32px;
    margin-right: 32px;
    font-family: "Sofia Pro", sans-serif;
    font-size: 15px;
    display: flex;
    cursor: pointer;
  }
  .scrollers .this-week .week-total {
    position: absolute;
    right: 130px;
  }
  .scrollers .this-week .total {
    position: absolute;
    right: 48px;
    font-size: 16px;
  }
  .scrollers .date-name {
    display: flex;
    background: #7b7b7b3b;
    margin: 0px 0px 8px;
  }
  .scrollers .date-name .spent {
    position: absolute;
    right: 20%;
    padding: 6px;
    font-size: 14px;
  }
  .scrollers .date-name .estimate {
    position: absolute;
    right: 8%;
    padding: 6px;
    font-size: 14px;
  }
  .scrollers .date-name span {
    padding: 8px;
    font-size: 12px;
    font-weight: lighter;
  }
  .scrollers .date-name .day-total {
    position: absolute;
    right: 32px;
    padding: 4px;
  }
  .scrollers .week-row {
    display: flex;
    padding: 16px;
    border: 1px solid #c9c9c9;
    margin-right: -16px;
    margin-left: -16px;
  }
  .scrollers .week-row textarea {
    width: 20%;
    border: 0;
    justify-content: center;
    background-color: transparent;
    min-height: 35px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 16px;
    margin-top: -3px;
    padding-top: 4px;
    padding-left: 2px;
  }
  .scrollers .week-row textarea:focus, .scrollers .week-row textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scrollers .week-row textarea:hover {
    border: 1px solid #c9c9c9;
  }
  .scrollers .week-row .header-project {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-top: -6px;
    color: #108EE9;
  }
  .scrollers .week-row .header-project span {
    margin-left: 8px;
    font-size: 14px;
    width: 96px;
  }
  .scrollers .week-row .header-project svg {
    height: 16px;
    width: 16px;
  }
  .scrollers .week-row .header-project:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .scrollers .week-row .header-timer {
    display: flex;
    position: absolute;
    right: 48px;
    margin-top: 6px;
  }
  .scrollers .week-row .header-timer .play-pause {
    display: flex;
    cursor: pointer;
    margin-left: 48px;
    align-items: center;
    margin-top: -4px;
    color: #108EE9;
  }
  .scrollers .week-row .header-timer .play-pause svg {
    height: 32px;
    width: 32px;
    padding: 5px;
  }
  .scrollers .week-row .header-timer .play-pause svg:hover {
    background: #eeeeee;
    border-radius: 50%;
    color: #5765b3;
  }
  .scrollers .week-row .header-estimate {
    display: flex;
    margin-left: 0px;
    margin-top: 0px;
    position: absolute;
    right: 6%;
  }
  .scrollers .week-row .header-estimate span {
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scrollers .week-row .header-estimate textarea {
    display: flex;
    width: 64px;
    border: 0;
    background-color: transparent;
    min-height: 35px;
    justify-content: center;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 8px;
    margin-top: -3px;
    padding-top: 5px;
    padding-left: 8px;
  }
  .scrollers .week-row .header-estimate textarea:focus, .scrollers .week-row .header-estimate textarea:active {
    outline: none;
    border-bottom: 0px solid #c9c9c9;
  }
  .scrollers .week-row .header-estimate textarea:hover {
    border: 1px solid #c9c9c9;
  }
}

/*# sourceMappingURL=Leave.css.map */

.body {
  font-family: "Sofia Pro", sans-serif; }
  .body .display-top {
    display: flex; }
    .body .display-top .title-text {
      padding-top: 5px;
      font-size: 18px; }
    .body .display-top .save-button {
      right: 0;
      cursor: pointer;
      margin-top: 8px;
      padding: 7px 24px;
      color: white;
      font-size: 15px;
      border-radius: 5px;
      background-color: #5765b3;
      position: absolute;
      font-family: "Sofia Pro", sans-serif; }
  .body .display-image {
    display: flex; }
    .body .display-image .edit-image {
      cursor: pointer;
      margin-top: 24px;
      margin-left: 55px;
      color: #5765b3;
      font-size: 14px;
      position: absolute;
      text-decoration: underline;
      text-underline: #5765b3;
      font-family: "Sofia Pro", sans-serif; }
  .body .display-user-list {
    display: flex; }
    .body .display-user-list .user-field {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-left: 16px;
      margin-right: 16px;
      color: #282c34;
      font-size: 13px; }
    .body .display-user-list .user-field2 {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-left: 16px;
      margin-right: 16px;
      color: #999999;
      font-size: 13px; }
    .body .display-user-list .divider-line {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 13px; }
    .body .display-user-list .edit-user {
      display: none;
      padding-top: 12px;
      padding-bottom: 12px;
      right: 8px;
      position: absolute;
      text-decoration: underline;
      text-underline: #5765b3;
      color: #5765b3;
      font-size: 13px; }
    .body .display-user-list .edit-user-role {
      display: none;
      margin-top: 22px;
      right: 215px;
      position: absolute;
      font-size: 13px; }
      .body .display-user-list .edit-user-role .MuiBadge-badge {
        font-family: "Sofia Pro", sans-serif !important;
        padding: 2px 8px !important;
        height: 24px !important;
        width: 215px;
        color: rgba(0, 0, 0, 0.51);
        background-color: #eaeaea; }
    .body .display-user-list .edit-user-workspace {
      display: none;
      margin-top: 22px;
      right: 180px;
      position: absolute;
      color: white;
      font-size: 13px; }
      .body .display-user-list .edit-user-workspace .MuiBadge-badge {
        font-family: "Sofia Pro", sans-serif !important;
        padding: 2px 8px !important;
        height: 24px !important;
        width: 140px;
        background-color: #5765b3; }
  .body .editHoverBGColor {
    padding-top: 8px;
    padding-bottom: 8px; }
    .body .editHoverBGColor:hover {
      cursor: pointer;
      transition: all 0.2s;
      background-color: rgba(87, 101, 179, 0.2); }
      .body .editHoverBGColor:hover .edit-user {
        display: inherit; }
      .body .editHoverBGColor:hover .edit-user-role {
        display: inherit; }
      .body .editHoverBGColor:hover .edit-user-workspace {
        display: inherit; }
  .body .display-field {
    display: flex; }
    .body .display-field .edit-field {
      margin-top: 48px; }
    .body .display-field .edit-field2 {
      margin-top: 48px;
      margin-left: 32px; }
    .body .display-field .MuiInputBase-fullWidth {
      width: 350px; }
    .body .display-field .MuiInputBase-root {
      font-family: "Sofia Pro", sans-serif; }
    .body .display-field .MuiFormLabel-root {
      font-family: "Sofia Pro", sans-serif; }

.profile-dialog {
  font-family: "Sofia Pro", sans-serif; }
  .profile-dialog .MuiTypography-h6 {
    font-family: "Sofia Pro", sans-serif !important;
    font-size: 16px;
    color: #282c34;
    text-align: left; }
  .profile-dialog .display-image-dia {
    display: flex; }
    .profile-dialog .display-image-dia .edit-image {
      cursor: pointer;
      margin-top: 4px;
      margin-left: 55px;
      color: #5765b3;
      font-size: 14px;
      position: absolute;
      text-decoration: underline;
      text-underline: #5765b3;
      font-family: "Sofia Pro", sans-serif; }
  .profile-dialog .edit-field-dia {
    margin-top: 24px;
    margin-bottom: 8px; }
    .profile-dialog .edit-field-dia .MuiInputBase-fullWidth {
      width: 350px; }
    .profile-dialog .edit-field-dia .MuiInputBase-root {
      font-family: "Sofia Pro", sans-serif; }
    .profile-dialog .edit-field-dia .MuiFormLabel-root {
      font-family: "Sofia Pro", sans-serif; }
  .profile-dialog .MuiDialogActions-root {
    height: 48px;
    background-color: rgba(175, 175, 175, 0.11); }
  .profile-dialog .save-button-dia {
    cursor: pointer;
    padding: 7px 24px;
    right: 24px;
    color: white;
    font-size: 15px;
    border-radius: 5px;
    background-color: #5765b3;
    position: absolute;
    font-family: "Sofia Pro", sans-serif; }
  .profile-dialog .save-button-dia2 {
    cursor: pointer;
    padding: 7px 24px;
    right: 120px;
    color: white;
    font-size: 15px;
    border-radius: 5px;
    background-color: rgba(87, 87, 87, 0.23);
    position: absolute;
    font-family: "Sofia Pro", sans-serif; }

/*# sourceMappingURL=Users.css.map */
